import { WebinarStatus } from '@qureos/types'

export interface IPasswordStrength {
  strength: 'WEAK' | 'MEDIUM' | 'STRONG'
  message: string
}

export const validatePassword = (password: string): IPasswordStrength => {
  const lengthValid = password && password.length >= 8
  if (!lengthValid)
    return {
      message:
        'Your password must contains at least 8 characters including an uppercase letter and a number',
      strength: 'WEAK'
    }

  const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
  if (password.match(regex)) {
    return {
      message: 'Great! Your password is strong.',
      strength: 'STRONG'
    }
  } else {
    if (password.search(/[a-z]/) < 0) {
      return {
        message: 'Include lowercase character to make your password stronger',
        strength: 'MEDIUM'
      }
    } else if (password.search(/[A-Z]/) < 0) {
      return {
        message: 'Include an uppercase letter to strengthen your password',
        strength: 'MEDIUM'
      }
    } else if (password.search(/[0-9]/) < 0) {
      return {
        message: 'Include a number to make your password stronger',
        strength: 'MEDIUM'
      }
    }
  }
}

export const getStatusColor = (status: string): string => {
  let color = ''
  switch (status) {
    case WebinarStatus.DRAFT: {
      color = 'lightgray'
      break
    }
    case WebinarStatus.IN_REVIEW: {
      color = 'orange'
      break
    }
    case WebinarStatus.APPROVED: {
      color = 'green'
      break
    }
    case WebinarStatus.EVENT_CANCELLED: {
      color = 'red'
      break
    }
    default: {
      color = 'geekblue'
      break
    }
  }

  return color
}
