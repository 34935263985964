import type { RadioChangeEvent } from 'antd'
import { Radio } from 'antd'
import clsx from 'clsx'
import { useRouter } from 'next/router'

interface Props {
  type: string
  modeIndex: string
  redirectTo: string
}

const AuthTabs: React.FC<Props> = ({
  type = 'signin',
  modeIndex,
  redirectTo = ''
}) => {
  const { push } = useRouter()

  const handleModeChange = (e: RadioChangeEvent) => {
    const val = e.target.value
    if (val === '1') {
      push(type === 'signin' ? '/corporate/login' : `/corporate/signup`)
    } else {
      push(type === 'signin' ? '/' : `/signup`)
    }
  }
  return (
    <Radio.Group
      onChange={handleModeChange}
      value={modeIndex}
      className="mb-6 bg-gray-200 flex gap-0 rounded-md overflow-hidden border-2 auth-style">
      <Radio.Button
        className={clsx(
          'w-1/2 border-none rounded-md text-center',
          modeIndex === '1'
            ? 'bg-white text-green-600'
            : 'bg-transparent text-gray-500 '
        )}
        value="1">
        I'm an employer
      </Radio.Button>
      <Radio.Button
        className={clsx(
          'w-1/2 border-none rounded-md text-center',
          modeIndex === '2' ? 'bg-white' : 'bg-transparent text-gray-500 '
        )}
        value="2">
        I'm a Candidate
      </Radio.Button>
    </Radio.Group>
  )
}

export default AuthTabs
