import clsx from 'clsx'
import { FC } from 'react'

interface Props {
  width?: string
  height?: string
}
export const Loading: FC<Props> = ({ width, height }) => (
  <div
    className={clsx(
      'flex flex-col items-center justify-center',
      width || 'w-full',
      height || 'h-screen'
    )}>
    <img src="/icon_loader.gif" className="w-[640px] pb-[100px]" />
  </div>
)
