import { Progress } from 'antd'

interface passwordStrength {
  strength: 'WEAK' | 'MEDIUM' | 'STRONG'
  message: string
}
interface Props {
  data: passwordStrength
}
export const PasswordStrength: React.FC<Props> = ({ data }) => {
  return (
    <div>
      <Progress
        size="small"
        strokeColor={
          data.strength === 'WEAK'
            ? '#C52A1A'
            : data.strength === 'MEDIUM'
              ? '#FFBF1F'
              : data.strength === 'STRONG'
                ? '#22992E'
                : ''
        }
        percent={
          data.strength === 'WEAK'
            ? 30
            : data.strength === 'MEDIUM'
              ? 60
              : data.strength === 'STRONG'
                ? 100
                : 0
        }
        showInfo={false}
      />
      <span
        className={
          data.strength === 'WEAK'
            ? 'text-red-500 text-sm'
            : data.strength === 'MEDIUM'
              ? 'text-yellow-500 text-sm'
              : data.strength === 'STRONG'
                ? 'text-green-500 text-sm'
                : ''
        }>
        {data?.message || ''}
      </span>
    </div>
  )
}
