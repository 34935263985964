import { FunctionComponent, useMemo, useState } from 'react'
import moment from 'moment'
import { Form } from 'antd'
import { useEffect } from 'react'

interface Props {
  handleChange: (dob: Date | undefined) => void
}

export const DOBPicker: FunctionComponent<Props> = ({ handleChange }) => {
  const [dateOfBirth, setDateOfBirth] = useState({
    dd: null,
    mm: null,
    yy: null
  })

  const commonSelectStyle =
    'text-sm border-gray-300 disabled:bg-gray-100 placeholder:text-gray-500 bg-white rounded-md font-inter w-full'
  const yearOptions = useMemo(() => {
    const currentDate = moment()
    const start = moment('Jan 1 1900').toDate().getFullYear()
    const end = currentDate.subtract(18, 'years').toDate().getFullYear()
    return Array(end - start + 1)
      .fill(start)
      .map((year, index) => ({
        value: year + index,
        label: year + index
      }))
      .reverse()
  }, [])

  const monthOptions = useMemo(() => {
    return moment.months().map((month, index) => ({
      label: month,
      value: index
    }))
  }, [])

  const dateOptions = useMemo(() => {
    const options = getDateOptions(dateOfBirth.mm, dateOfBirth.yy)
    return options.map(value => ({
      label: value,
      value
    }))
  }, [dateOfBirth.mm, dateOfBirth.yy])

  function getDateOptions(month: string, year: string) {
    const date = new Date(+year, +month + 1, 0).getDate()
    const dates: string[] = []
    for (let day = 1; day < date + 1; day++) {
      dates.push(day < 10 ? '0' + day : String(day))
    }
    return dates
  }

  useEffect(() => {
    const { dd, mm, yy } = dateOfBirth
    if (dd && mm && yy) {
      const dob = new Date()
      dob.setUTCFullYear(+yy, +mm, +dd)
      handleChange(dob)
    } else {
      handleChange(undefined)
    }
  }, [dateOfBirth])

  const onChangeSelect = (name: string, value: string) => {
    const updatedDateOfBirth = { ...dateOfBirth }
    updatedDateOfBirth[name] = value
    if (updatedDateOfBirth.dd) {
      const date = updatedDateOfBirth.dd
      const dateOptions = getDateOptions(
        updatedDateOfBirth.mm,
        updatedDateOfBirth.yy
      )
      const index = dateOptions.findIndex(value => value === date)
      if (index === -1) {
        updatedDateOfBirth.dd = null
      }
    }
    setDateOfBirth(updatedDateOfBirth)
  }

  return (
    <Form autoComplete="off" className="grid gap-3 grid-cols-3">
      <div className="w-full">
        <select
          name="yy"
          value={dateOfBirth.yy || ''}
          className={commonSelectStyle}
          onChange={event => {
            onChangeSelect('yy', event.target.value)
          }}>
          <option disabled value="">
            Year
          </option>
          {yearOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full">
        <select
          name="mm"
          value={dateOfBirth?.mm || ''}
          className={commonSelectStyle}
          onChange={event => {
            onChangeSelect('mm', event.target.value)
          }}
          disabled={dateOfBirth.yy === null}>
          <option disabled value="">
            Month
          </option>
          {monthOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full">
        <select
          className={commonSelectStyle}
          name="dd"
          value={dateOfBirth.dd || ''}
          onChange={event => {
            onChangeSelect('dd', event.target.value)
          }}
          disabled={dateOfBirth.mm === null}>
          <option disabled value="">
            Day
          </option>
          {dateOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </Form>
  )
}
