import { ICancellationPolicy, Skill } from '@qureos/types'
import { OfferingEnum } from '..'
export * from './webinarApplication'

interface durationDTO {
  units: number
  measure: string
}

export interface WebinarPricing {
  amount: number
  discount?: number
  cancellationPolicy?: ICancellationPolicy
}

export enum WebinarStatus {
  DRAFT = 'draft', // will be used
  PUBLISHED = 'published', //
  IN_REVIEW = 'review', // will be used
  APPROVED = 'approved', // will be used
  PENDING = 'pending',
  EVENT_CANCELLED = 'event_cancelled'
}

export interface WebinarDTO {
  _id?: string

  createdDateTime?: string

  updatedDateTime?: string

  createdBy?: any

  isEmailSent?: boolean

  title: string

  description: string

  photo: string

  duration: durationDTO

  callLink: string

  time: string

  seats: number

  language: string

  timezone?: string

  pricing: WebinarPricing

  status: string

  skills: Skill[]

  icsUrl: string

  startDateTime: Date

  endDateTime: Date
  urlSlug?: string

  offering?: OfferingEnum

  recordingUrl?: string
}
