import {
  IntlTelData,
  IntlTelInput
} from '@/components/shared/IntlTelInput/IntlTelInput'
import { DOBPicker, Field, ShouldRender } from '@/components/shared/misc'
import { Label } from '@/components/shared/misc/Typography/Label'
import { CountryProps } from '@qureos/types'
import clsx from 'clsx'
import { Dictionary } from 'lodash'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface StepOneData {
  dateOfBirth?: Date
  email: string
  firstName: string
  lastName: string
  password: string
  confirmPassword?: string
  phoneNumber?: string
  isPhoneValid?: boolean
  phoneCountry?: CountryProps
  company?: string
}

interface Props {
  data: StepOneData
  errors?: Dictionary<JSX.Element | string>
  onChange: (stepOneData: StepOneData) => void
  isApprentice?: boolean
  showCount?: boolean
  showLabels?: boolean
  maxPasswordLength?: number
  fieldGaps?: 'small' | 'medium' | 'large'
}

export const CorporateSignupStep1: FunctionComponent<Props> = ({
  data,
  errors,
  onChange,
  isApprentice = false,
  showCount = false,
  showLabels = false,
  fieldGaps = 'medium',
  maxPasswordLength
}) => {
  const { t: commonLabel } = useTranslation('common')
  const [userData, setUserData] = useState(data)

  const handleShowLabel = value => {
    if (showLabels) return value
    else return null
  }

  useEffect(() => {
    onChange(userData)
  }, [userData])

  const inputClasses = [
    'font-normal',
    'text-gray-700',
    'placeholder-gray-500',
    'rounded-md',
    'border',
    'border-solid',
    'border-gray-300',
    'py-2',
    'leading-tight',
    'px-3.5',
    'outline-0',
    'focus-visible:ring-0',
    isApprentice ? 'focus:border-blue-600' : 'focus:border-corporate-green'
  ]

  const handleNumberChange = ({
    phone,
    phoneCountryCode,
    phonePrefix,
    isPhoneValid,
    countryName
  }: IntlTelData) => {
    setUserData(prevData => ({
      ...prevData,
      phoneNumber: phone,
      isPhoneValid,
      phoneCountry: {
        name: countryName,
        countryCode: phoneCountryCode,
        dialCode: phonePrefix
      }
    }))
  }
  const handleGaps = useMemo(() => {
    switch (fieldGaps) {
      case 'small':
        return 'gap-2'
      case 'medium':
        return 'gap-4'
      case 'large':
        return 'gap-6'
      default:
        return 'gap-4'
    }
  }, [fieldGaps])
  return (
    <div className={clsx('w-full flex flex-col', handleGaps)}>
      <div className="w-full md:flex space-x-0 md:gap-2 md:space-y-0 space-y-2">
        <div className="w-full">
          <Field
            className={clsx(inputClasses)}
            type="text"
            labelClass="text-gray-700 font-medium"
            label={handleShowLabel(commonLabel('FIRST_NAME'))}
            placeholder={commonLabel('FIRST_NAME')}
            value={userData.firstName}
            onChange={value =>
              setUserData({ ...userData, firstName: String(value) })
            }
            error={errors && errors['firstName']}
          />
        </div>
        <div className="w-full">
          <Field
            className={clsx(inputClasses)}
            type="text"
            label={handleShowLabel(commonLabel('LAST_NAME'))}
            labelClass="text-gray-700 font-medium"
            placeholder={commonLabel('LAST_NAME')}
            value={userData.lastName}
            onChange={value =>
              setUserData({ ...userData, lastName: String(value) })
            }
            error={errors && errors['lastName']}
          />
        </div>
      </div>

      <div className="w-full">
        <Field
          className={clsx(inputClasses)}
          type="email"
          label={handleShowLabel(commonLabel('EMAIL_ADDRESS'))}
          labelClass="text-gray-700 font-medium"
          placeholder={
            isApprentice ? commonLabel('EMAIL') : commonLabel('BUSINESS_EMAIL')
          }
          value={userData.email}
          onChange={value => setUserData({ ...userData, email: String(value) })}
          error={errors && errors['email']}
        />
      </div>
      <ShouldRender check={isApprentice}>
        <div className="w-full">
          <Label
            data={{
              text: handleShowLabel(commonLabel('DATE_OF_BIRTH')),
              className: 'text-gray-700 font-medium'
            }}
          />
          <DOBPicker
            handleChange={dateOfBirth =>
              setUserData({ ...userData, dateOfBirth })
            }
          />
          <ShouldRender check={errors && errors['dateOfBirth']}>
            <div className="inline-flex items-center text-red-500">
              {errors && errors['dateOfBirth']}
            </div>
          </ShouldRender>
        </div>
      </ShouldRender>
      <div className="w-full md:flex space-x-0 md:gap-2 md:space-y-0 space-y-2">
        <div className="w-full">
          <Field
            className={clsx(inputClasses)}
            type="password"
            label={handleShowLabel(commonLabel('PASSWORD'))}
            labelClass="text-gray-700 font-medium"
            placeholder={commonLabel('PASSWORD')}
            showCount={showCount}
            maxLength={maxPasswordLength}
            value={userData.password}
            onChange={value =>
              setUserData({ ...userData, password: String(value) })
            }
            error={errors && errors['password']}
          />
        </div>
        <div className="w-full">
          <Field
            className={clsx(inputClasses)}
            type="password"
            label={handleShowLabel(commonLabel('CONFIRM_PASSWORD'))}
            labelClass="text-gray-700 font-medium"
            placeholder={commonLabel('CONFIRM_PASSWORD')}
            showCount={showCount}
            maxLength={maxPasswordLength}
            value={userData.confirmPassword}
            onChange={value =>
              setUserData({ ...userData, confirmPassword: String(value) })
            }
            error={errors && errors['confirmPassword']}
          />
        </div>
      </div>
      <ShouldRender check={!isApprentice}>
        <div className="w-full corporate">
          <div className="flex flex-col gap-1">
            <ShouldRender check={showLabels}>
              <Label
                data={{
                  text: commonLabel('PHONE_NUMBER'),
                  className: 'text-gray-700 font-medium'
                }}
              />
            </ShouldRender>
            <IntlTelInput
              phone={data.phoneNumber}
              phoneCountryCode={data?.phoneCountry?.countryCode || ''}
              onChange={handleNumberChange}
              focusBorderColor={
                isApprentice
                  ? 'focus:border-blue-600'
                  : 'focus:border-corporate-green'
              }
            />
          </div>
        </div>
      </ShouldRender>
      <ShouldRender check={!isApprentice}>
        <div className="w-full">
          <Field
            className={clsx(inputClasses)}
            type="text"
            label={handleShowLabel(commonLabel('COMPANY_NAME'))}
            labelClass="text-gray-700 font-medium"
            placeholder={commonLabel('COMPANY_NAME')}
            value={userData?.company}
            onChange={value =>
              setUserData({ ...userData, company: String(value) })
            }
          />
        </div>
      </ShouldRender>
    </div>
  )
}
